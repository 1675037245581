<script>
import { Errors } from "form-backend-validation";
import moment from "moment";
import { ItemDetail } from "./components";
import { mapActions, mapState } from "vuex/dist/vuex.common.js";
export default {
    name: "stockAdjustmentCreate",
    components: {
        ItemDetail
    },
    data() {
        return {
            loading: false,
            errors: new Errors(),
            shops: [],
            inventories: [],
            model: {
                shop_id: undefined,
                adjustment_date: moment().format("DD-MM-YYYY"),
                remark: "",
                sub_item_list: []
            }
        };
    },
    computed: {
        ...mapState("inventory/stockAdjustment", ["formModels"]),
        shopList() {
            return this.shops.map(el => ({
                value: el.shop_id,
                label: el.shop_name
            }));
        }
    },
    mounted() {
        this.fetchViewModel();
    },
    methods: {
        ...mapActions("inventory/stockAdjustment", [
            "getFormViewData",
            "store"
        ]),
        fetchViewModel() {
            this.loading = true;
            this.shops = [];
            this.getFormViewData({
                params: {
                    fnName: "shop"
                }
            })
                .then(result => {
                    const { shop } = result.data;
                    this.shops = shop ? shop : [];
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => (this.loading = false));
        },
        onShopChange() {
            this.model.sub_item_list = [];
            this.loading = true;
            this.getFormViewData({
                params: {
                    fnName: "inventory",
                    shop_id: this.model.shop_id
                }
            })
                .then(result => {
                    const { inventory } = result.data;
                    this.inventories = inventory ? inventory : [];
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => (this.loading = false));
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$router.push({ name: "stock-adjustment" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.shop_id = undefined;
            this.model.adjustment_date = moment().format("DD-MM-YYYY");
            this.model.remark = "";
            this.model.sub_item_list = [];
        }
    }
};
</script>

<template>
    <div>
        <ts-page-title
            :title="$t('stockAdjustment.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('stockAdjustment.pageTitle'),
                    href: '/admin/inventory/stock-adjustments'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-loading-banner :loading="loading">
            <ts-panel>
                <ts-panel-wrapper class="tw-space-y-1">
                    <div class="row">
                        <div class="col-md-3 tw-space-y-1">
                            <label class="required">{{
                                $t("stockAdjustment.stockAdjustmentDate")
                            }}</label
                            ><br />
                            <a-date-picker
                                v-model="model.adjustment_date"
                                :allowClear="false"
                                format="DD-MM-YYYY"
                                valueFormat="DD-MM-YYYY"
                                style="width: 100%"
                            />
                            <div
                                class="tw-text-red-600 tw-text-xs"
                                v-if="errors.has('adjustment_date')"
                            >
                                {{ errors.first("adjustment_date") }}
                            </div>
                        </div>
                        <div class="tw-space-y-1 col-md-3">
                            <label class="required">{{
                                $t("stockAdjustment.shopName")
                            }}</label>
                            <a-select
                                v-model="model.shop_id"
                                :options="shopList"
                                showSearch
                                :filterOption="
                                    (input, option) =>
                                        option.componentOptions.children[0].text
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                "
                                @change="onShopChange"
                                class="tw-w-full"
                                :placeholder="$t('bookingPickup.shopName')"
                            ></a-select>
                            <div
                                class="tw-text-red-600 tw-text-xs"
                                v-if="errors.has('shop_id')"
                            >
                                {{ errors.first("shop_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="required">{{
                                $t("stockAdjustment.remark")
                            }}</label>
                            <a-textarea
                                v-model="model.remark"
                                :placeholder="$t('stockAdjustment.remark')"
                                :auto-size="{ minRows: 3, maxRows: 5 }"
                            />
                            <div
                                class="tw-text-red-600 tw-text-xs"
                                v-if="errors.has('remark')"
                            >
                                {{ errors.first("remark") }}
                            </div>
                        </div>
                    </div>

                    <ItemDetail
                        v-model="model"
                        :inventories="inventories"
                        :validate="errors"
                        class="tw-mb-3"
                    />
                    <p
                        v-if="errors.has('sub_item_list')"
                        class="tw-text-red-500"
                    >
                        {{ errors.first("sub_item_list") }}
                    </p>
                    <div class="row">
                        <div
                            class="col-md-12 tw-space-x-3 tw-flex tw-justify-end"
                        >
                            <ts-button
                                @click.prevent="
                                    $router.push({ name: 'stock-adjustment' })
                                "
                                :disabled="loading"
                                >{{ $t("cancel") }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                outline
                                @click.prevent="onSaveAddNew"
                                >{{ $t("saveAddNew") }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                @click.prevent="onSave"
                                >{{ $t("save") }}</ts-button
                            >
                        </div>
                    </div>
                </ts-panel-wrapper>
            </ts-panel>
        </ts-loading-banner>
    </div>
</template>
