var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('stockAdjustment.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('stockAdjustment.pageTitle'),
                href: '/admin/inventory/stock-adjustments'
            },
            {
                text: _vm.$t('create'),
                active: true
            }
        ]}}),_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-space-y-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 tw-space-y-1"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("stockAdjustment.stockAdjustmentDate")))]),_c('br'),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"allowClear":false,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},model:{value:(_vm.model.adjustment_date),callback:function ($$v) {_vm.$set(_vm.model, "adjustment_date", $$v)},expression:"model.adjustment_date"}}),(_vm.errors.has('adjustment_date'))?_c('div',{staticClass:"tw-text-red-600 tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first("adjustment_date"))+" ")]):_vm._e()],1),_c('div',{staticClass:"tw-space-y-1 col-md-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("stockAdjustment.shopName")))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"options":_vm.shopList,"showSearch":"","filterOption":function (input, option) { return option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0; },"placeholder":_vm.$t('bookingPickup.shopName')},on:{"change":_vm.onShopChange},model:{value:(_vm.model.shop_id),callback:function ($$v) {_vm.$set(_vm.model, "shop_id", $$v)},expression:"model.shop_id"}}),(_vm.errors.has('shop_id'))?_c('div',{staticClass:"tw-text-red-600 tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first("shop_id"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("stockAdjustment.remark")))]),_c('a-textarea',{attrs:{"placeholder":_vm.$t('stockAdjustment.remark'),"auto-size":{ minRows: 3, maxRows: 5 }},model:{value:(_vm.model.remark),callback:function ($$v) {_vm.$set(_vm.model, "remark", $$v)},expression:"model.remark"}}),(_vm.errors.has('remark'))?_c('div',{staticClass:"tw-text-red-600 tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first("remark"))+" ")]):_vm._e()],1)]),_c('ItemDetail',{staticClass:"tw-mb-3",attrs:{"inventories":_vm.inventories,"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),(_vm.errors.has('sub_item_list'))?_c('p',{staticClass:"tw-text-red-500"},[_vm._v(" "+_vm._s(_vm.errors.first("sub_item_list"))+" ")]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-x-3 tw-flex tw-justify-end"},[_c('ts-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'stock-adjustment' })}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('ts-button',{attrs:{"color":"primary","outline":""},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("saveAddNew")))]),_c('ts-button',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("save")))])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }